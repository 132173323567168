import React, { useEffect, useContext, useState } from "react";
import { MessageContext } from "utils/context/messageContext";
import Layout from "components/Layout";
import BasicTable from "components/Table";
import useIsMobile from "utils/hooks/mobileHook";
import MobileViewMessages from "components/MobileViewMessages";
import LoaderAnimation from "utils/animation/loader";
import NoMessagesAnimation from "utils/animation/noMessages";
import { fetchUnreadMessages, fetchUserMessages } from "utils/apiRequests";
import Filter from "components/Messages/filter";
import moment from "moment";

const MessagePage = () => {
  const { messagesType } = useContext(MessageContext);
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [application, setApplication] = useState("All");
  const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

  const handleApplicationChange = (event) => {
    setApplication(event.target.value);
  };

  const fetchInformation = async () => {
    try {
      setIsLoading(true);
      if (messagesType === "unread") {
        const response = await fetchUnreadMessages(currentDate);
        setUnreadMessages(response.data);
        setDataAvailable(response.data.length > 0);
      } else if (messagesType === "all") {
        const response = await fetchUserMessages(currentDate);
        setUserMessages(response.data);
        setDataAvailable(response.data.length > 0);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInformation();
  }, [messagesType]);

  return (
    <Layout>
      {isMobile ? (
        <>
          <MobileViewMessages
            messages={messagesType === "unread" ? unreadMessages : userMessages}
            isLoading={isLoading}
            dataAvailable={dataAvailable}
          />
        </>
      ) : isLoading ? (
        <LoaderAnimation />
      ) : dataAvailable ? (
        <>
          <Filter
            application={application}
            handleApplicationChange={handleApplicationChange}
          />
          <BasicTable
            messages={
              application === "All"
                ? messagesType === "unread"
                  ? unreadMessages
                  : userMessages
                : messagesType === "unread"
                ? unreadMessages.filter(
                    (msg) => msg.application_name === application
                  )
                : userMessages.filter(
                    (msg) => msg.application_name === application
                  )
            }
          />
        </>
      ) : (
        <NoMessagesAnimation />
      )}
    </Layout>
  );
};

export default MessagePage;
