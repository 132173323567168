import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MessageContext } from "utils/context/messageContext";

const MessageCard = ({ message }) => {
  const { setActiveMessage } = useContext(MessageContext);
  const navigate = useNavigate();

  const handleClick = () => {
    setActiveMessage(message);
    navigate(`/message/${message.messageid}`);
  };

  return (
    <Card
      sx={{ maxWidth: 345, backgroundColor: "#d8e5f0", borderRadius: "20px" }}
      onClick={handleClick}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          <strong>{message.application_name}</strong>
        </Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
          {message.subject}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {message.message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MessageCard;
