import React from "react";
import { Box } from "@mui/material";
import WSPLogo from "../wsp_rgb.png";
import useIsMobile from "utils/hooks/mobileHook";
import SignInForm from "components/Auth/signin";

const Home = () => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "100vh",
        backgroundImage:
          "linear-gradient(to bottom right,rgb(179,214,236), rgb(216,230,240), rgb(255,255,255))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "475px",
          position: "relative",
          borderRadius: "16PX",
        }}
      >
        <img
          src={WSPLogo}
          style={{
            height: "140px",
            marginBottom: "30px",
            width: "290px",
            paddingTop: "10px",
            paddingBottom: "2px",
          }}
        />
        <SignInForm />
      </Box>
    </Box>
  );
};

export default Home;
