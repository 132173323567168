// PrivateRoute.js

import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "utils/context/authContext";

const PrivateRoute = ({ children }) => {
  const { userAuthenticated } = useContext(AuthContext);

  return userAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
