import React, { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserStatus, login, logout } from "utils/apiRequests";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userAuthenticated, setUserAuthentication] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    try {
      const response = await login(data);

      if (response.status === 200) {
        await response.json();
        setUserAuthentication(true);
        navigate("/messages");
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.status === 200) {
        setUserAuthentication(false);
        navigate("/");
      } else {
        console.error("Error logging out");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const verifyAuth = async () => {
    try {
      const response = await getUserStatus();
      if (response.status === 200) {
        setUserAuthentication(true);
        navigate("/messages");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error while verifying user:", error);
    }
  };

  useEffect(async () => {
    verifyAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        userAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
