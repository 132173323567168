const config = require("config.json");
const baseServerRoute = config.api_url;

async function getHeader() {
  return new Headers({
    "Content-Type": "application/json",
  });
}

export async function getUserMessages(timestamp) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: headers,
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/user/?timestamp=${timestamp}`
  ); // eslint-disable-line no-undef
  return [request, options];
}

export async function getUserUnreadMessages(timestamp) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/user/unread/?timestamp=${timestamp}`
  ); // eslint-disable-line no-undef
  return [request, options];
}

export async function getAllMessages() {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/messages/admin`); // eslint-disable-line no-undef
  return [request, options];
}

export async function getMessage(messageId) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/messages/admin/${messageId}`); // eslint-disable-line no-undef
  return [request, options];
}

export async function getFile(attachmetId) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/attachment/${attachmetId}`
  ); // eslint-disable-line no-undef
  return [request, options];
}

export async function getUserMessagesForApplication(userId, applicationId) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    mode: "cors",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/application/${applicationId}/user/${userId}`
  ); // eslint-disable-line no-undef
  return [request.options];
}

export async function markMessageAsRead(messageId) {
  const headers = await getHeader();
  const options = {
    method: "PUT",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/admin/message/${messageId}`
  ); // eslint-disable-line no-undef
  return [request, options];
}

export async function expireMessages(messageIds) {
  const headers = await getHeader();
  const options = {
    method: "PUT",
    headers: headers,
    credentials: "include",
    body: JSON.stringify({ messageIds: messageIds }),
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/messages/admin/expire`); // eslint-disable-line no-undef
  return [request, options];
}

export async function deleteMessages(messageIds) {
  const headers = await getHeader();
  const options = {
    method: "DELETE",
    headers: headers,
    credentials: "include",
    body: JSON.stringify({ messageIds: messageIds }),
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/messages/admin/delete`); // eslint-disable-line no-undef
  return [request, options];
}

export async function getMessageCount(timestamp) {
  const headers = await getHeader();
  const options = {
    method: "GET",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(
    `${baseServerRoute}/messages/user/count/?timestamp=${timestamp}`
  ); // eslint-disable-line no-undef
  return [request, options];
}

export async function getApplications() {
  const headers = await getHeader();
  const options = {
    method: "GET",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/applications`); // eslint-disable-line no-undef
  return [request, options];
}

export async function postMessage(messageFormData) {
  const options = {
    method: "POST",
    credentials: "include",
    body: messageFormData,
  };
  const request = new Request(`${baseServerRoute}/messages/admin`); // eslint-disable-line no-undef
  return [request, options];
}

export async function editMessage(messageFormData, messageId) {
  const options = {
    method: "PUT",
    credentials: "include",
    body: messageFormData,
  };
  const request = new Request(`${baseServerRoute}/messages/admin/${messageId}`); // eslint-disable-line no-undef
  return [request, options];
}

export async function verifyAuth() {
  const options = {
    method: "GET",
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/check-auth`);
  return [request, options];
}

export async function getLoginOptions(data) {
  const headers = await getHeader();
  const options = {
    method: "POST",
    headers: headers,
    credentials: "include",
    cache: "default",
    body: JSON.stringify(data),
  };
  const request = new Request(`${baseServerRoute}/login`);
  return [request, options];
}

export async function logOutOptions() {
  const options = {
    method: "GET",
    credentials: "include",
  };
  const request = new Request(`${baseServerRoute}/logout`);
  return [request, options];
}

export async function getUserRoles() {
  const headers = await getHeader();
  const options = {
    method: "GET",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/user/get-roles`);
  return [request, options];
}

export async function getAllClients() {
  const headers = await getHeader();
  const options = {
    method: "GET",
    headers: headers,
    credentials: "include",
    cache: "default",
  };
  const request = new Request(`${baseServerRoute}/identityService/listTenants`);
  return [request, options];
}
