import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Container, Typography, Paper } from "@mui/material";
import { AuthContext } from "utils/context/authContext";

const SignInForm = () => {
  const { control, handleSubmit } = useForm();
  const { handleLogin } = useContext(AuthContext);
  const onSubmit = async (data) => {
    await handleLogin(data);
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        DecisionVue Message Center
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", marginTop: 16 }}
      >
        <Controller
          name="username"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="primary"
          style={{ marginTop: 16 }}
        >
          Sign In
        </Button>
      </form>
    </>
  );
};

export default SignInForm;
